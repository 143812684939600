
// RGPD Management
const days = 90;// days time to save cookie choice
const cookieSaved = getCookie("rgpd_save");
const cookieConsent = getCookie("rgpd_consent");
const cookiePartial = getCookie("rgpd_partial");

// Config cookies expiration date
var date = new Date();
date.setTime(date.getTime() + (days*24*60*60*1000));
var expires = "; expires=" + date.toUTCString();

// Init RGPD in second time to wait all loading
window.onload = initDoc;

function initDoc() {

    var acceptRgpd = document.querySelectorAll(".accept-all-rgpd");
    var denyRgpd = document.querySelectorAll(".deny-all-rgpd");
    var partialRgpd = document.getElementsByClassName("cookie-valid-partial")[0];
    //var denyRgpdSub = document.getElementById("deny-all-rgpd-sub");
    var closeRgpd = document.getElementsByClassName("cross-rgpd")[0];
    var detailsRgpd = document.getElementsByClassName("rgpd-details-link")[0];
    var closeDetailsRgpd = document.querySelectorAll(".rgpd-details-close");
    var showEssentialTableDetails = document.getElementsByClassName("show-essentials")[0];
    var hideEssentialTableDetails = document.getElementsByClassName("hide-essentials")[0];
    var showStatistiquesTableDetails = document.getElementsByClassName("show-statistiques")[0];
    var hideStatistiquesTableDetails = document.getElementsByClassName("hide-statistiques")[0];
    var showMarketingTableDetails = document.getElementsByClassName("show-marketing")[0];
    var hideMarketingTableDetails = document.getElementsByClassName("hide-marketing")[0];

    acceptRgpd.forEach(function(acceptButton) {
        acceptButton.onclick = RGPDaccepted;
    });

    denyRgpd.forEach(function(denyButton) {
        denyButton.onclick = RGPDdenied;
    });

    closeDetailsRgpd.forEach(function(closeElement) {
        closeElement.onclick = RGPDcloseDetails;
    })

    partialRgpd.onclick = RGPDpartial;
    //closeRgpd.onclick = RGPDdenied;
    detailsRgpd.onclick = RGPDshowDetails;
    showEssentialTableDetails.onclick = showEssentials;
    hideEssentialTableDetails.onclick = hideEssentials;
    showStatistiquesTableDetails.onclick = showStatistiques;
    hideStatistiquesTableDetails.onclick = hideStatistiques;
    showMarketingTableDetails.onclick = showMarketing;
    hideMarketingTableDetails.onclick = hideMarketing;

    if(cookieSaved != "true") {
        initRgpd();
    }

    if(cookieConsent == "true") {
        RGPDaccepted();
    }

    if(cookieConsent == "false") {
        RGPDdenied();
    }

    if(cookieConsent == "partial") {
        RGPDpartial();
    }

    function initRgpd() {
        document.querySelector('.drawer-rgpd').classList.remove('hidden');
        document.querySelector('.modal-rgpd').classList.remove('hidden');
        document.querySelector('.drawer-rgpd').classList.add('open');
        document.querySelector('.modal-rgpd').classList.add('open');

        document.body.classList.add("no-scroll");
    }

    var denyAll = document.querySelectorAll('.cross-rgpd span,.cookie-valid-partial, button, .deny-all-rgpd');
    
    denyAll.forEach( function(elementDeny) {
        elementDeny.addEventListener('click', function () {
            console.log(denyAll);
            document.querySelector('.modal-rgpd').classList.remove('open');
            document.querySelector('.modal-rgpd').classList.add('hidden');
            document.querySelector('.drawer-rgpd').classList.remove('open');
            document.querySelector('.drawer-rgpd').classList.add('hidden');

            // Close details modal too
            document.querySelector('.modal-rgpd-details').classList.remove('open');
            document.querySelector('.modal-rgpd-details').classList.add('hidden');
            document.querySelector('.drawer-rgpd-details').classList.remove('open');
            document.querySelector('.drawer-rgpd-details').classList.add('hidden');

            document.body.classList.remove("no-scroll");
        })
    });

    function RGPDshowDetails() {
        document.querySelector('.modal-rgpd').classList.remove('open');
        document.querySelector('.modal-rgpd').classList.add('hidden');
        document.querySelector('.drawer-rgpd').classList.remove('open');
        document.querySelector('.drawer-rgpd').classList.add('hidden');

        document.querySelector('.drawer-rgpd-details').classList.remove('hidden');
        document.querySelector('.modal-rgpd-details').classList.remove('hidden');
        document.querySelector('.drawer-rgpd-details').classList.add('open');
        document.querySelector('.modal-rgpd-details').classList.add('open');
    }

    function RGPDcloseDetails() {
        document.querySelector('.modal-rgpd').classList.remove('hidden');
        document.querySelector('.modal-rgpd').classList.add('open');
        document.querySelector('.drawer-rgpd').classList.remove('hidden');
        document.querySelector('.drawer-rgpd').classList.add('open');

        document.querySelector('.drawer-rgpd-details').classList.remove('open');
        document.querySelector('.modal-rgpd-details').classList.remove('open');
        document.querySelector('.drawer-rgpd-details').classList.add('hidden');
        document.querySelector('.modal-rgpd-details').classList.add('hidden');

        document.body.classList.remove("no-scroll");
    }

    function RGPDaccepted() {
    // All RGPD accepted, we list all

        addEssentials();
        addStatistiques();
        addMarketing();

        // Add cookie to save choice if saving checked
        var saveCheck = document.getElementById("save-rgpd");
    
        if(saveCheck.checked == true) {
            document.cookie = "rgpd_save="+ saveCheck.checked + expires;
            document.cookie = "rgpd_consent=true" + expires;
        }

    }

    function RGPDdenied() {

        addEssentials();

         // Add cookie to save choice if checked
         var saveCheck = document.getElementById("save-rgpd");
        
         if(saveCheck.checked == true) {
             document.cookie = "rgpd_save="+ saveCheck.checked + expires;
             document.cookie = "rgpd_consent=false" + expires;
         }
    }

    function RGPDpartial() {
    // Some RGPD accepted

        addEssentials();
        
        var statistiquesCheck = document.getElementById("stats");
        var marketingCheck = document.getElementById("marketing");
        
        if(statistiquesCheck.checked == true 
            || cookiePartial == "statistique"
            || cookiePartial == "both") {
            addStatistiques();
        }

        if(marketingCheck.checked == true
            || cookiePartial == "marketing"
            || cookiePartial == "both") {
            addMarketing();
        }

        // Add cookie to save choice if saving checked
        var saveCheck = document.getElementById("save-rgpd");
    
        if(saveCheck.checked == true) {

            document.cookie = "rgpd_save="+ saveCheck.checked + expires;
            document.cookie = "rgpd_consent=partial" + expires;

            if(statistiquesCheck.checked == true) {
                document.cookie = "rgpd_partial=statistiques" + expires;
            }

            if(marketingCheck.checked == true) {
                document.cookie = "rgpd_partial=marketing" + expires;
            }

            if((marketingCheck.checked == true) && (statistiquesCheck.checked == true)) {
                document.cookie = "rgpd_partial=both" + expires;
            }
        }
    }

    // we add real iframe if RPGD accepted
    function addIframe() {
        
    }

    // We create a sandox iframe to limit foreign iframe cookie access
    function falseIframe() {
       
    }

    function addEssentials() {
        // Add here your essentials RGPD to inject
        addIframe();
    }

    function addMarketing() {
        // Add here your marketing RGPD to inject
    }

    function addStatistiques() {
        // Add here your Statistiques RGPD to inject
    }

    //modal management
    // var ml = document.querySelector('.ml');
    // console.log(ml)
    // ml.addEventListener('click', function () {
    //     document.querySelector('.modal').classList.add('open');
    //     document.querySelector('.modal').classList.remove('hidden');
    //     document.querySelector('.legals').classList.add('open');
    //     document.querySelector('.legals').classList.remove('hidden');
    //     document.querySelector('.contacts').classList.add('hidden');
    //     document.querySelector('.contacts').classList.remove('open');
    //     document.querySelector('.drawer').classList.add('open');
    //     document.querySelector('.drawer').classList.remove('hidden');
    //  });
 
    //  document.querySelector('.contact').addEventListener('click', function () {
    //      document.querySelector('.modal').classList.add('open');
    //      document.querySelector('.modal').classList.remove('hidden');
    //      document.querySelector('.contacts').classList.add('open');
    //      document.querySelector('.contacts').classList.remove('hidden');
    //      document.querySelector('.legals').classList.add('hidden');
    //      document.querySelector('.legals').classList.remove('open');
    //      document.querySelector('.drawer').classList.add('open');
    //      document.querySelector('.drawer').classList.remove('hidden');
    //   });
  
    // var closeIt = document.querySelector('.cross span, .drawer');
    // console.log(closeIt);
    //  document.querySelector('.cross span, .drawer').addEventListener('click', function () {
    //      document.querySelector('.modal').classList.remove('open');
    //      document.querySelector('.modal').classList.add('hidden');
    //      document.querySelector('.drawer').classList.remove('open');
    //      document.querySelector('.drawer').classList.add('hidden');
    //      document.querySelector('.contacts').classList.remove('open');
    //      document.querySelector('.legals').classList.remove('open');
 
    //      document.querySelector('.partner-modal').classList.remove('open');
    //      document.querySelector('.partner-modal').classList.add('hidden');
    //  })
 
     function showEssentials() {
         document.querySelector('#essentials-table').classList.add('open');
         document.querySelector('#essentials-table').classList.remove('hidden');
 
         document.querySelector('.hide-essentials').classList.remove("hidden");
         document.querySelector('.hide-essentials').classList.add("open");
         
         document.querySelector('.show-essentials').classList.remove("open");
         document.querySelector('.show-essentials').classList.add("hidden");
     }
 
     function hideEssentials() {
         document.querySelector('#essentials-table').classList.remove('open');
         document.querySelector('#essentials-table').classList.add('hidden');
 
         document.querySelector('.hide-essentials').classList.remove("open");
         document.querySelector('.hide-essentials').classList.add("hidden");
         
         document.querySelector('.show-essentials').classList.remove("hidden");
         document.querySelector('.show-essentials').classList.add("open");
     }
 
     function showStatistiques() {
         document.querySelector('#statistiques-table').classList.add('open');
         document.querySelector('#statistiques-table').classList.remove('hidden');
 
         document.querySelector('.hide-statistiques').classList.remove("hidden");
         document.querySelector('.hide-statistiques').classList.add("open");
         
         document.querySelector('.show-statistiques').classList.remove("open");
         document.querySelector('.show-statistiques').classList.add("hidden");
     }
 
     function hideStatistiques() {
         document.querySelector('#statistiques-table').classList.remove('open');
         document.querySelector('#statistiques-table').classList.add('hidden');
 
         document.querySelector('.hide-statistiques').classList.remove("open");
         document.querySelector('.hide-statistiques').classList.add("hidden");
         
         document.querySelector('.show-statistiques').classList.remove("hidden");
         document.querySelector('.show-statistiques').classList.add("open");
     }
 
     function showMarketing() {
         document.querySelector('#marketing-table').classList.add('open');
         document.querySelector('#marketing-table').classList.remove('hidden');
 
         document.querySelector('.hide-marketing').classList.remove("hidden");
         document.querySelector('.hide-marketing').classList.add("open");
         
         document.querySelector('.show-marketing').classList.remove("open");
         document.querySelector('.show-marketing').classList.add("hidden");
     }
 
     function hideMarketing() {
         document.querySelector('#marketing-table').classList.remove('open');
         document.querySelector('#marketing-table').classList.add('hidden');
 
         document.querySelector('.hide-marketing').classList.remove("open");
         document.querySelector('.hide-marketing').classList.add("hidden");
         
         document.querySelector('.show-marketing').classList.remove("hidden");
         document.querySelector('.show-marketing').classList.add("open");
     }
} // end of RGPD Management

 // To get cookies name
 function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
}

// Petit fonction fade in native
function fadeIn(el, time) {
    el.style.opacity = 0;
  
    var last = +new Date();
    var tick = function() {
      el.style.opacity = +el.style.opacity + (new Date() - last) / time;
      last = +new Date();
  
      if (+el.style.opacity < 1) {
        (window.requestAnimationFrame && requestAnimationFrame(tick)) || setTimeout(tick, 16);
      }
    };
  
    tick();
}